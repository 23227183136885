import axios from 'axios';

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.cookie.split("=")[1];

//const baseUrl = 'http://localhost:4810/Api';

export default {




    //********************************************************************| Authintecations |***********************************************************

    login(bodyObjeect) {
        return axios.post(`/Security/login`, bodyObjeect);
    },

    IsLoggedin() {
        return axios.get(`/Security/IsLoggedin`);
    },

    Logout() {
        return axios.post(`/Security/Logout`);
    },








    // ********************************| Start Dictionaries |******************************


    // PaymentMethods
    GetPaymentMethods(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Dictionaries/PaymentMethods/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetAllPaymentMethods() {
        return axios.get(`api/admin/Dictionaries/PaymentMethods/GetAll`);
    },

    AddPaymentMethods(bodyObject) {
        return axios.post(`api/admin/Dictionaries/PaymentMethods/Add`, bodyObject);
    },

    EditPaymentMethods(bodyObject) {
        return axios.post(`api/admin/Dictionaries/PaymentMethods/Edit`, bodyObject);
    },

    DeletePaymentMethods(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/PaymentMethods/Delete`);
    },

    ChangeStatusPaymentMethods(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/PaymentMethods/ChangeStatus`);
    },





    // Subjects
    GetSubjects(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Dictionaries/Subjects/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetAllSubjects() {
        return axios.get(`api/admin/Dictionaries/Subjects/GetAll`);
    },

    AddSubjects(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Subjects/Add`, bodyObject);
    },

    EditSubjects(bodyObject) {
        return axios.post(`api/admin/Dictionaries/Subjects/Edit`, bodyObject);
    },

    DeleteSubjects(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Subjects/Delete`);
    },

    ChangeStatusSubjects(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Subjects/ChangeStatus`);
    },





    // AcademicLevels
    GetAcademicLevels(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Dictionaries/AcademicLevels/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetAllAcademicLevels() {
        return axios.get(`api/admin/Dictionaries/AcademicLevels/GetAll`);
    },

    AddAcademicLevels(bodyObject) {
        return axios.post(`api/admin/Dictionaries/AcademicLevels/Add`, bodyObject);
    },

    EditAcademicLevels(bodyObject) {
        return axios.post(`api/admin/Dictionaries/AcademicLevels/Edit`, bodyObject);
    },

    DeleteAcademicLevels(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/AcademicLevels/Delete`);
    },

    ChangeStatusAcademicLevels(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/AcademicLevels/ChangeStatus`);
    },




    // AcademicSpecializations
    GetAcademicSpecializations(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Dictionaries/AcademicSpecializations/Get?pageno=${pageNo}&pagesize=${pageSize}
        &Search=${Search}`);
    },

    GetAllAcademicSpecializations(Id) {
        return axios.get(`api/admin/Dictionaries/AcademicSpecializations/GetAll?AcademicLevelId=${Id}`);
    },

    AddAcademicSpecializations(bodyObject) {
        return axios.post(`api/admin/Dictionaries/AcademicSpecializations/Add`, bodyObject);
    },

    EditAcademicSpecializations(bodyObject) {
        return axios.post(`api/admin/Dictionaries/AcademicSpecializations/Edit`, bodyObject);
    },

    DeleteAcademicSpecializations(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/AcademicSpecializations/Delete`);
    },

    ChangeStatusAcademicSpecializations(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/AcademicSpecializations/ChangeStatus`);
    },








    // Faq
    GetFaq(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Dictionaries/Faq/Get?pageno=${pageNo}&pagesize=${pageSize}
        &Search=${Search}`);
    },

    DeleteFaq(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Faq/Delete`);
    },

    ChangeStatusFaq(Id) {
        return axios.post(`api/admin/Dictionaries/${Id}/Faq/ChangeStatus`);
    },




    GetDashboardInfo() {
        return axios.get(`api/admin/Dictionaries/Dashboard/Get`);
    },



    // ********************************| End Dictionaries |********************************
























    // ********************************| Courses |************************************

    GetCourses(pageNo, pageSize, Search, IsFree, IsDiscount, SalesStatus, ViewStatus) {
        return axios.get(`api/admin/Courses/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}
            &IsFree=${IsFree}&IsDiscount=${IsDiscount}&SalesStatus=${SalesStatus}&Status=${ViewStatus}`);
    },

    GetAllCourses() {
        return axios.get(`api/admin/Courses/GetAll`);
    },

    GetAllCoursesBySubjectSpecialization(SubjectId, AcademicSpecializationId) {
        return axios.get(`api/admin/Courses/GetBySubjectSpecialization?SubjectId=${SubjectId}&AcademicSpecializationId=${AcademicSpecializationId}`);
    },

    AddCourses(bodyObject) {
        return axios.post(`api/admin/Courses/Add`, bodyObject);
    },

    EditCourses(bodyObject) {
        return axios.post(`api/admin/Courses/Edit`, bodyObject);
    },

    DeleteCourses(Id) {
        return axios.post(`api/admin/Courses/${Id}/Delete`);
    },

    ChangeStatusCourses(Id) {
        return axios.post(`api/admin/Courses/${Id}/ChangeStatus`);
    },

    ChangeSalesStatusCourses(Id) {
        return axios.post(`api/admin/Courses/${Id}/ChangeSalesStatus`);
    },

    CloseCourses(Id) {
        return axios.post(`api/admin/Courses/${Id}/Close`);
    },




    //Closed Course
    GetClosedCourses(pageNo, pageSize, Search, IsFree, IsDiscount) {
        return axios.get(`api/admin/Courses/GetClosed?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}
            &IsFree=${IsFree}&IsDiscount=${IsDiscount}`);
    },

    OpenCourses(Id) {
        return axios.post(`api/admin/Courses/${Id}/Open`);
    },


    //ChartInfo
    GetAllCoursesChartInfo() {
        return axios.get(`api/admin/Courses/Chart/GetAll`);
    },


    //ChartInfo
    GetCoursesChartInfo(Id) {
        return axios.get(`api/admin/Courses/Chart/Get?Id=${Id}`);
    },


   





    //Shapters
    GetCourseShapters(Id, Search) {
        return axios.get(`api/admin/Courses/Shapters/Get?Id=${Id}&Search=${Search}`);
    },

    GetAllShapters(Id) {
        return axios.get(`api/admin/Courses/Shapters/GetAll?CourseId=${Id}`);
    },

    AddShapters(bodyObject) {
        return axios.post(`api/admin/Courses/Shapters/Add`, bodyObject);
    },

    EditShapters(bodyObject) {
        return axios.post(`api/admin/Courses/Shapters/Edit`, bodyObject);
    },

    DeleteShapters(Id) {
        return axios.post(`api/admin/Courses/${Id}/Shapters/Delete`);
    },

    ChangeStatusShapters(Id) {
        return axios.post(`api/admin/Courses/${Id}/Shapters/ChangeStatus`);
    },






    //Lectures
    GetCourseLectures(CourseId,ShapterId, Search) {
        return axios.get(`api/admin/Courses/Lectures/Get?CourseId=${CourseId}&ShapterId=${ShapterId}&Search=${Search}`);
    },

    AddLectures(bodyObject) {
        return axios.post(`api/admin/Courses/Lectures/Add`, bodyObject);
    },

    EditLectures(bodyObject) {
        return axios.post(`api/admin/Courses/Lectures/Edit`, bodyObject);
    },

    DeleteLectures(Id) {
        return axios.post(`api/admin/Courses/${Id}/Lectures/Delete`);
    },

    ChangeStatusLectures(Id) {
        return axios.post(`api/admin/Courses/${Id}/Lectures/ChangeStatus`);
    },






    //LecturesAttashments
    GetCourseLecturesAttashments(LectureId, Search) {
        return axios.get(`api/admin/Courses/LecturesAttashments/Get?LectureId=${LectureId}&Search=${Search}`);
    },

    AddLecturesAttashments(bodyObject) {
        return axios.post(`api/admin/Courses/LecturesAttashments/Add`, bodyObject);
    },

    EditLecturesAttashments(bodyObject) {
        return axios.post(`api/admin/Courses/LecturesAttashments/Edit`, bodyObject);
    },

    DeleteLecturesAttashments(Id) {
        return axios.post(`api/admin/Courses/${Id}/LecturesAttashments/Delete`);
    },

    ChangeStatusLecturesAttashments(Id) {
        return axios.post(`api/admin/Courses/${Id}/LecturesAttashments/ChangeStatus`);
    },






    //Exams
    GetCourseExams(CourseId,ShapterId, Search) {
        return axios.get(`api/admin/Courses/Exams/Get?CourseId=${CourseId}&ShapterId=${ShapterId}&Search=${Search}`);
    },

    AddExams(bodyObject) {
        return axios.post(`api/admin/Courses/Exams/Add`, bodyObject);
    },

    EditExams(bodyObject) {
        return axios.post(`api/admin/Courses/Exams/Edit`, bodyObject);
    },

    DeleteExams(Id) {
        return axios.post(`api/admin/Courses/${Id}/Exams/Delete`);
    },

    ChangeStatusExams(Id) {
        return axios.post(`api/admin/Courses/${Id}/Exams/ChangeStatus`);
    },






    //ExamsQuestions
    GetCourseExamsQuestions(ExamId, Search) {
        return axios.get(`api/admin/Courses/ExamsQuestions/Get?ExamId=${ExamId}&Search=${Search}`);
    },

    AddExamsQuestions(bodyObject) {
        return axios.post(`api/admin/Courses/ExamsQuestions/Add`, bodyObject);
    },

    EditExamsQuestions(bodyObject) {
        return axios.post(`api/admin/Courses/ExamsQuestions/Edit`, bodyObject);
    },

    DeleteExamsQuestions(Id) {
        return axios.post(`api/admin/Courses/${Id}/ExamsQuestions/Delete`);
    },

    ChangeStatusExamsQuestions(Id) {
        return axios.post(`api/admin/Courses/${Id}/ExamsQuestions/ChangeStatus`);
    },


    //Students
    GetCourseStudents(Id, Search) {
        return axios.get(`api/admin/Courses/Students/Get?Id=${Id}&Search=${Search}`);
    },


    //Review
    GetCourseReview(Id, Search) {
        return axios.get(`api/admin/Courses/Review/Get?Id=${Id}&Search=${Search}`);
    },





    //// ********************************| End Courses |********************************















    //// ********************************| Students |********************************


    GetStudents(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Students/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    AddStudents(bodyObject) {
        return axios.post(`api/admin/Students/Add`, bodyObject);
    },

    EditStudents(bodyObject) {
        return axios.post(`api/admin/Students/Edit`, bodyObject);
    },

    ChangeStatusStudents(Id) {
        return axios.post(`api/admin/Students/${Id}/ChangeStatus`);
    },

    ResetDeviceStudents(Id) {
        return axios.post(`api/admin/Students/${Id}/ResetDevice`);
    },

    ResetPasswordStudents(Id) {
        return axios.post(`api/admin/Students/${Id}/ResetPassword`);
    },

    DeleteStudents(Id) {
        return axios.post(`api/admin/Students/${Id}/Delete`);
    },

    //Suspends
    SuspendsStudents(bodyObject) {
        return axios.post(`api/admin/Students/Suspends/Add`, bodyObject);
    },

    GetSuspendsStudents(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Students/Suspends/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    CanselSuspends(Id) {
        return axios.post(`api/admin/Students/${Id}/Suspends/Cansel`);
    },





    //ChangeRequest
    GetChangeRequest(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Students/ChangeRequest/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    AcceptChangeRequest(Id) {
        return axios.post(`api/admin/Students/${Id}/ChangeRequest/Accept`);
    },

    AcceptAllChangeRequest() {
        return axios.post(`api/admin/Students/ChangeRequest/AcceptAll`);
    },

    RejectChangeRequest(Id) {
        return axios.post(`api/admin/Students/${Id}/ChangeRequest/Reject`);
    },

    DeleteChangeRequest(Id) {
        return axios.post(`api/admin/Students/${Id}/ChangeRequest/Delete`);
    },

    DeleteAllChangeRequest() {
        return axios.post(`api/admin/Students/ChangeRequest/DeleteAll`);
    },





    //CoursesRequests
    GetCoursesRequests(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Students/CoursesRequests/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    AcceptCoursesRequests(Id) {
        return axios.post(`api/admin/Students/${Id}/CoursesRequests/Accept`);
    },

    RejectCoursesRequests(Id) {
        return axios.post(`api/admin/Students/${Id}/CoursesRequests/Reject`);
    },

    DeleteCoursesRequests(Id) {
        return axios.post(`api/admin/Students/${Id}/CoursesRequests/Delete`);
    },

    







    //Studetns Courses
    GetStudentsCourses(Id, Search) {
        return axios.get(`api/admin/Students/Courses/Get?Id=${Id}&Search=${Search}`);
    },

    AddStudentsCourses(bodyObject) {
        return axios.post(`api/admin/Students/Courses/Add`, bodyObject);
    },

    AddStudentsCoursesValue(bodyObject) {
        return axios.post(`api/admin/Students/Courses/AddValue`, bodyObject);
    },

    DropStudentsCourses(bodyObject) {
        return axios.post(`api/admin/Students/Courses/Drop`, bodyObject);
    },







    //Wallet
    GetStudentsWallet(Id) {
        return axios.get(`api/admin/Students/Wallet/Get?Id=${Id}`);
    },

    AddStudentsWalletValue(bodyObject) {
        return axios.post(`api/admin/Students/Wallet/Charge`, bodyObject);
    },






    //Devices
    GetStudentsDevices(Id) {
        return axios.get(`api/admin/Students/Devices/Get?Id=${Id}`);
    },


    GetAllStudentsChartInfo() {
        return axios.get(`api/admin/Students/Chart/GetAll`);
    },



    //// ********************************| End Of Students |********************************













    // ********************************| Instructors |********************************



    GetInstructors(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Instructors/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetAllInstructors() {
        return axios.get(`api/admin/Instructors/GetAll`);
    },

    AddInstructors(bodyObject) {
        return axios.post(`api/admin/Instructors/Add`, bodyObject);
    },

    EditInstructors(bodyObject) {
        return axios.post(`api/admin/Instructors/Edit`, bodyObject);
    },

    ChangeStatusInstructors(Id) {
        return axios.post(`api/admin/Instructors/${Id}/ChangeStatus`);
    },

    ResetDeviceInstructors(Id) {
        return axios.post(`api/admin/Instructors/${Id}/ResetDevice`);
    },

    ResetPasswordInstructors(Id) {
        return axios.post(`api/admin/Instructors/${Id}/ResetPassword`);
    },

    DeleteInstructors(Id) {
        return axios.post(`api/admin/Instructors/${Id}/Delete`);
    },

    //Suspends
    SuspendsInstructors(bodyObject) {
        return axios.post(`api/admin/Instructors/Suspends/Add`, bodyObject);
    },

    GetSuspendsInstructors(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Instructors/Suspends/Get?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    CanselInstructors(Id) {
        return axios.post(`api/admin/Instructors/${Id}/Suspends/Cansel`);
    },



    // ********************************| End Of Instructors |********************************












    // ********************************| Financial |********************************

    GetSubscriptions(pageNo, pageSize, Search, From, To) {
        return axios.get(`api/admin/Financial/GetSubscriptions?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}
        &From=${From}&To=${To}`);
    },

    GetRecharge(pageNo, pageSize, Search, From, To, UserId, DistributorsId, PaymentMethodId) {
        return axios.get(`api/admin/Financial/GetRecharge?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}
        &From=${From}&To=${To}&UserId=${UserId}&DistributorsId=${DistributorsId}&PaymentMethodId=${PaymentMethodId}`);
    },

    GetWalletTracker(pageNo, pageSize, Search, From, To, ProcessType, Channel) {
        return axios.get(`api/admin/Financial/GetWalletTracker?pageno=${pageNo}&pagesize=${pageSize}&Search=${Search}
        &From=${From}&To=${To}&ProcessType=${ProcessType}&Channel=${Channel}`);
    },





    // ********************************| End Of Financial |********************************














    // ********************************| VoucherCards |********************************

    GetVoucherCards(pageNo, pageSize, Search, DistributorsId) {
        return axios.get(`api/admin/VoucherCards/Get?pageNo=${pageNo}&pagesize=${pageSize}&Search=${Search}&DistributorsId=${DistributorsId}`);
    },

    AddVoucherCards(bodyObject) {
        return axios.post('api/admin/VoucherCards/Add', bodyObject);
    },

    SaleVoucherCards(Id) {
        return axios.post(`api/admin/VoucherCards/${Id}/Sale`, { responseType: 'blob' });
    },

    ForzePackage(Id) {
        return axios.post(`api/admin/VoucherCards/${Id}/Freeze`, { responseType: 'blob' });
    },

    DeletePackage(Id) {
        return axios.post(`api/admin/VoucherCards/${Id}/Delete`);
    },



    //Card
    GetCards() {
        return axios.get(`api/admin/VoucherCards/Cards/Get`);
    },

    GetCardInfoBySerialNumber(SerialNumber) {
        return axios.get(`api/admin/VoucherCards/Cards/GetCardInfoBySerialNumber?SerialNumber=${SerialNumber}`);
    },

    GetCardInfoByVoucherNumber(VoucherNumber) {
        return axios.get(`api/admin/VoucherCards/Cards/GetCardInfoByVoucherNumber?SerialNumber=${VoucherNumber}`);
    },


    //TryAttemp
    GetVoucherCardsTryAttemp(pageNo, pageSize, Search) {
        return axios.get(`api/admin/VoucherCards/TryAttemp/Get?pageNo=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },



    //Chart
    GetVoucherCardsChart() {
        return axios.get(`api/admin/VoucherCards/Chart/Get`);
    },



    


    //Distributors
    GetDistributors(pageNo, pageSize, Search) {
        return axios.get(`api/admin/VoucherCards/Distributors/Get?pageNo=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetAllDistributors() {
        return axios.get(`api/admin/VoucherCards/Distributors/GetAll`);
    },

    AddDistributors(bodyObject) {
        return axios.post('api/admin/VoucherCards/Distributors/Add', bodyObject);
    },

    EditDistributors(bodyObject) {
        return axios.post('api/admin/VoucherCards/Distributors/Edit', bodyObject);
    },

    ResetPasswordDistributors(Id) {
        return axios.post(`api/admin/VoucherCards/${Id}/Distributors/ResetPassword`);
    },

    ChangeStatusDistributors(Id) {
        return axios.post(`api/admin/VoucherCards/${Id}/Distributors/ChangeStatus`);
    },

    DeleteDistributors(Id) {
        return axios.post(`api/admin/VoucherCards/${Id}/Distributors/Delete`);
    },




    ActivePackgeDistributors(bodyObject) {
        return axios.post('api/admin/VoucherCards/Distributors/ActivePackge', bodyObject);
    },

    GetDistributorsPackages(pageNo, pageSize) {
        return axios.get(`api/admin/VoucherCards/Distributors/Packages/Get?pageNo=${pageNo}&pagesize=${pageSize}`);
    },

    // ********************************| End Of VoucherCards |********************************












    // ********************************| Users |********************************
    GetUsers(pageNo, pageSize, Search) {
        return axios.get(`api/admin/Users/Get?pageNo=${pageNo}&pagesize=${pageSize}&Search=${Search}`);
    },

    GetUsersTransactions(pageNoT, pageSizeT, Id) {
        return axios.get(`api/admin/Users/GetTransactions?pageNo=${pageNoT}&pagesize=${pageSizeT}&Id=${Id}`);
    },

    GetAllUsers() {
        return axios.get(`api/admin/Users/GetAll`);
    },

    AddUsers(bodyObject) {
        return axios.post('api/admin/Users/Add', bodyObject);
    },

    EditUsers(bodyObject) {
        return axios.post('api/admin/Users/Edit', bodyObject);
    },

    ResetPasswordUsers(Id) {
        return axios.post(`api/admin/Users/${Id}/ResetPassword`);
    },

    ChangeStatusUsers(Id) {
        return axios.post(`api/admin/Users/${Id}/ChangeStatus`);
    },

    DeleteUsers(Id) {
        return axios.post(`api/admin/Users/${Id}/Delete`);
    },


    //USerProfile 
    ChangeProfilePicture(bodyObject) {
        return axios.post('api/admin/Users/Profile/ChangePicture', bodyObject);
    },

    ChangeProfileInfo(bodyObject) {
        return axios.post('api/admin/Users/Profile/ChangeInfo', bodyObject);
    },

    ChangeProfilePassword(bodyObject) {
        return axios.post('api/admin/Users/Profile/ChangePassword', bodyObject);
    },




    // ********************************| End Of Users |********************************




    
    
}